import React from "react";
import { Article } from "../../components/Article";

import styles from "./grid.module.css";

export const ArticlesGrid = ({ type, list, className }) => {
  return (
    <div className={`${styles[`grid-type${type}`]} ${styles.grid} ${className}`}>
      {list.length > 0 &&
        list.map((article, index) => (
          <Article
            key={index}
            date={article.date}
            descr={article.descr}
            img={article.img}
            title={article.title}
            likes={article.likes}
            views={article.liskes}
            type={article.type}
            alt={article.alt}
          />
        ))}
    </div>
  );
};
