export const Share = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4536 0C12.4955 0 10.9081 1.49238 10.9081 3.33333C10.9081 3.53427 10.927 3.73104 10.9633 3.92217L5.80256 7.31854C5.77634 7.3358 5.75152 7.35424 5.72813 7.37373C5.12622 6.93074 4.36815 6.66667 3.54448 6.66667C1.58637 6.66667 -0.000976562 8.15908 -0.000976562 10C-0.000976562 11.8409 1.58637 13.3333 3.54448 13.3333C4.36815 13.3333 5.12622 13.0692 5.72813 12.6263C5.75152 12.6457 5.77634 12.6642 5.80256 12.6814L10.9633 16.0778C10.927 16.2689 10.9081 16.4657 10.9081 16.6667C10.9081 18.5076 12.4955 20 14.4536 20C16.4116 20 17.999 18.5076 17.999 16.6667C17.999 14.8257 16.4116 13.3333 14.4536 13.3333C13.3051 13.3333 12.2841 13.8468 11.6362 14.6428L6.74963 11.4268C6.96785 10.9943 7.08993 10.5106 7.08993 10C7.08993 9.48944 6.96785 9.00574 6.74963 8.57323L11.6362 5.35723C12.2841 6.15325 13.3051 6.66667 14.4536 6.66667C16.4116 6.66667 17.999 5.17429 17.999 3.33333C17.999 1.49238 16.4116 0 14.4536 0ZM12.5445 3.33333C12.5445 2.34205 13.3992 1.53846 14.4536 1.53846C15.5079 1.53846 16.3627 2.34205 16.3627 3.33333C16.3627 4.32462 15.5079 5.12821 14.4536 5.12821C13.3992 5.12821 12.5445 4.32462 12.5445 3.33333ZM3.54448 8.20513C2.49011 8.20513 1.63539 9.00872 1.63539 10C1.63539 10.9913 2.49011 11.7949 3.54448 11.7949C4.59884 11.7949 5.45357 10.9913 5.45357 10C5.45357 9.00872 4.59884 8.20513 3.54448 8.20513ZM14.4536 14.8718C13.3992 14.8718 12.5445 15.6754 12.5445 16.6667C12.5445 17.6579 13.3992 18.4615 14.4536 18.4615C15.5079 18.4615 16.3627 17.6579 16.3627 16.6667C16.3627 15.6754 15.5079 14.8718 14.4536 14.8718Z"
      fill="#636363"
    />
  </svg>
);
