import { InviteCard } from "../../components/InviteCard/InviteCard";
import styles from "./grid.module.css";

export const InviteGrid = ({ list, className }) => {
  return (
    <div className={`${styles.grid} ${className}`}>
      {list.length > 0 &&
        list.map((card, index) => <InviteCard key={index} svg={card.svg} descr={card.descr} />)}
    </div>
  );
};
