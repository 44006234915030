export const Logo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28919 4.99642C2.87045 5.00786 2.51797 5.31196 2.43581 5.72451L0.178588 17.0597C0.17734 17.0659 0.176098 17.0722 0.174862 17.0784L0.151515 17.1957L0.152681 17.1943C0.0524131 17.7374 0 18.2973 0 18.8696C0 23.9122 4.07014 28 9.09091 28C14.1117 28 18.1818 23.9122 18.1818 18.8696C18.1818 13.827 14.1117 9.73913 9.09091 9.73913C8.16631 9.73913 7.27395 9.87776 6.43332 10.1355L6.51515 10.0435L7.30196 5.82893C7.36763 5.47717 7.11828 5.1442 6.76329 5.11311C5.48074 5.00082 4.57022 4.96145 3.28919 4.99642ZM13.3333 18.8696C13.3333 21.2228 11.4339 23.1304 9.09091 23.1304C6.74788 23.1304 4.84848 21.2228 4.84848 18.8696C4.84848 16.5164 6.74788 14.6087 9.09091 14.6087C11.4339 14.6087 13.3333 16.5164 13.3333 18.8696Z"
      fill="#FAFAFA"
    />
    <path
      d="M7.87879 2.28261C7.87879 3.54326 6.86125 4.56522 5.60606 4.56522C4.35087 4.56522 3.33333 3.54326 3.33333 2.28261C3.33333 1.02196 4.35087 0 5.60606 0C6.86125 0 7.87879 1.02196 7.87879 2.28261Z"
      fill="#A04A4A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0303 27.6957C19.1181 27.6957 20 26.81 20 25.7174C20 24.6248 19.1181 23.7391 18.0303 23.7391C16.9425 23.7391 16.0606 24.6248 16.0606 25.7174C16.0606 26.81 16.9425 27.6957 18.0303 27.6957ZM18.0303 26.4783C18.4487 26.4783 18.7879 26.1376 18.7879 25.7174C18.7879 25.2972 18.4487 24.9565 18.0303 24.9565C17.6119 24.9565 17.2727 25.2972 17.2727 25.7174C17.2727 26.1376 17.6119 26.4783 18.0303 26.4783Z"
      fill="#FAFAFA"
    />
  </svg>
);
