export const LikeShapes = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <path
      d="M35.8324 8.16641C38.1324 6.19974 41.899 6.19974 44.2323 8.16641L49.4991 12.7001C50.4991 13.5668 52.3656 14.2667 53.699 14.2667H59.3657C62.899 14.2667 65.799 17.1664 65.799 20.6998V26.3667C65.799 27.6667 66.499 29.5667 67.3657 30.5667L71.8991 35.8332C73.8657 38.1332 73.8657 41.9 71.8991 44.2333L67.3657 49.4998C66.499 50.4998 65.799 52.3665 65.799 53.6998V59.3668C65.799 62.9001 62.899 65.7999 59.3657 65.7999H53.699C52.399 65.7999 50.4991 66.4998 49.4991 67.3664L44.2323 71.8997C41.9323 73.8664 38.1657 73.8664 35.8324 71.8997L30.5657 67.3664C29.5657 66.4998 27.699 65.7999 26.3657 65.7999H20.599C17.0657 65.7999 14.1657 62.9001 14.1657 59.3668V53.6665C14.1657 52.3665 13.4657 50.4998 12.6324 49.4998L8.13237 44.1999C6.19904 41.8999 6.19904 38.1666 8.13237 35.8666L12.6324 30.5667C13.4657 29.5667 14.1657 27.7 14.1657 26.4V20.6998C14.1657 17.1664 17.0657 14.2667 20.599 14.2667H26.3657C27.6657 14.2667 29.5657 13.5668 30.5657 12.7001L35.8324 8.16641Z"
      stroke="#FFB26B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.9661 49.4001L37.7994 53.1334C38.4328 53.7668 39.8328 54.0669 40.7661 54.0669H46.6661C48.5328 54.0669 50.5661 52.6669 51.0328 50.8003L54.7661 39.4001C55.5327 37.2334 54.1328 35.3335 51.7995 35.3335H45.5661C44.6328 35.3335 43.8661 34.5671 43.9995 33.4671L44.7661 28.4671C45.0661 27.0671 44.1328 25.5003 42.7328 25.0337C41.4995 24.567 39.9328 25.2002 39.2995 26.1335L32.8994 35.6334"
      stroke="#04378E"
      strokeWidth="3"
      strokeliterlimit="10"
    />
    <path
      d="M24.999 49.4002V34.1337C24.999 31.9337 25.9324 31.167 28.1324 31.167H29.699C31.8657 31.167 32.8324 31.9337 32.8324 34.1337V49.4002C32.8324 51.5669 31.899 52.3669 29.699 52.3669H28.1324C25.9324 52.3669 24.999 51.6002 24.999 49.4002Z"
      stroke="#04378E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
