export const Sun = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="27"
    viewBox="0 0 44 27"
    fill="none"
  >
    <circle cx="22" cy="5" r="22" fill="#FFE24D" />
  </svg>
);
