import React from "react";

import { Like, Share, View } from "../Icons";

import styles from "./article.module.css";

export const Article = ({
  type,
  img,
  alt,
  title,
  descr,
  date,
  likes,
  views,
}) => {
  return (
    <article className={styles.article}>
      {type && (
        <span
          className={`${styles.type} ${type === "Реклама" && styles.typeAd}`}
        >
          {type}
        </span>
      )}
      <div className={styles.imgWrapper}>
        <img src={img} alt={alt} className={styles.img} />
      </div>
      <div
        className={`${styles.context} ${
          type === "Реклама" && styles.advertisement
        }`}
      >
        <h2 className={`${styles.title} ${type === "Реклама" && styles.titleAd}`}>{title}</h2>
        <p className={`${styles.descr} ${type === "Реклама" && styles.descrAd}`}>{descr}</p>
        {type !== "Реклама" && (
          <div className={styles.info}>
            <p className={styles.date}>{date}</p>
            <div className={styles.actions}>
              <span className={styles.likes}>
                {Like}
                {likes}
              </span>
              <span className={styles.views}>
                {View}
                {views}
              </span>
              <span className={styles.share}>{Share}</span>
            </div>
          </div>
        )}
      </div>
    </article>
  );
};
