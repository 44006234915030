export const JoinUs = (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.7656 11.6998L51.6323 23.4331C52.4323 25.0665 54.5656 26.6331 56.3656 26.9331L66.999 28.6998C73.799 29.8331 75.399 34.7665 70.499 39.6331L62.2323 47.8998C60.8323 49.2998 60.0656 51.9998 60.499 53.9331L62.8656 64.1665C64.7323 72.2665 60.4323 75.3998 53.2656 71.1665L43.299 65.2665C41.499 64.1998 38.5323 64.1998 36.699 65.2665L26.7323 71.1665C19.599 75.3998 15.2656 72.2331 17.1323 64.1665L19.499 53.9331C19.9323 51.9998 19.1656 49.2998 17.7656 47.8998L9.49896 39.6331C4.6323 34.7665 6.19897 29.8331 12.999 28.6998L23.6323 26.9331C25.399 26.6331 27.5323 25.0665 28.3323 23.4331L34.199 11.6998C37.399 5.33314 42.599 5.33314 45.7656 11.6998Z"
      stroke="#FFB26B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7473 22.6057L47.5606 30.2324C48.0806 31.294 49.4673 32.3124 50.6373 32.5074L57.5489 33.6557C61.9689 34.3924 63.0089 37.599 59.8239 40.7624L54.4506 46.1357C53.5406 47.0457 53.0423 48.8007 53.3239 50.0574L54.8623 56.709C56.0756 61.974 53.2806 64.0107 48.6223 61.259L42.1439 57.424C40.9739 56.7307 39.0456 56.7307 37.8539 57.424L31.3756 61.259C26.7389 64.0107 23.9223 61.9524 25.1356 56.709L26.6739 50.0574C26.9556 48.8007 26.4573 47.0457 25.5473 46.1357L20.1739 40.7624C17.0106 37.599 18.0289 34.3924 22.4489 33.6557L29.3606 32.5074C30.5089 32.3124 31.8956 31.294 32.4156 30.2324L36.2289 22.6057C38.3089 18.4674 41.6889 18.4674 43.7473 22.6057Z"
      stroke="#04378E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
