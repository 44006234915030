import { Fragment } from "react";
import { Link } from "react-router-dom";

import { Container } from "../../components/Container";
import { Circle, Logo } from "../../components/Icons";

import styles from "./footer.module.css";

export const Footer = () => {
  const listNav = [
    {
      context: (
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link to="/">О проекте</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Обработка данных</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Этикет обсуждений</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Размещение рекламы</Link>
          </li>
        </ul>
      ),
    },
    {
      context: (
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link to="/">Новости</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Справочник</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Решения</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Медиа</Link>
          </li>
        </ul>
      ),
    },
    {
      context: (
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link to="/">Инвалидные коляски</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Пандусы, подъемники, лифты</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Бытовые устройства</Link>
          </li>
          <li className={styles.item}>
            <Link to="/">Ручное управление для авто</Link>
          </li>
        </ul>
      ),
    },
    {
      context: (
        <div className={`${styles.list} ${styles["list-contact"]}`}>
          <p className={styles.item}>Связаться с нами </p>
          <a href="mailto:bfreesolutions@gmail.com" className={styles.item}>
            bfreesolutions@gmail.com
          </a>
          <div className={styles.circles}>
            {Circle}
            {Circle}
            {Circle}
            {Circle}
          </div>
        </div>
      ),
    },
  ];

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.wrapper}>
          <nav className={styles.nav}>
            {listNav.map((list, index) => (
              <Fragment key={index}>{list.context}</Fragment>
            ))}
          </nav>
          <p className={styles.copyright}>
            {Logo}
            <span>© 2023 Безбарьерные решения</span>
          </p>
        </div>
      </Container>
    </footer>
  );
};
