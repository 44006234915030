export const Rain = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="25"
    viewBox="0 0 44 25"
    fill="none"
  >
    <path
      d="M18.1247 23.875L21.9997 16.75M28.458 23.875L32.333 16.75M7.79134 23.875L11.6663 16.75M13.733 9.625C6.88467 9.625 1.33301 4.74818 1.33301 -1.26746C1.33301 -6.25342 5.46634 -10.8594 11.6663 -11.75C13.6112 -15.8492 18.2141 -18.875 23.5825 -18.875C30.4593 -18.875 36.0799 -14.147 36.4663 -8.1875C40.1158 -6.77293 42.6663 -3.30115 42.6663 0.457974C42.6663 5.52076 38.0398 9.625 32.333 9.625H13.733Z"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
