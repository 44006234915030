export const Support = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <path
      d="M59.999 62.8668H57.4657C54.799 62.8668 52.2657 63.9001 50.399 65.7668L44.699 71.4003C42.099 73.967 37.8657 73.967 35.2657 71.4003L29.5657 65.7668C27.699 63.9001 25.1324 62.8668 22.499 62.8668H19.999C14.4657 62.8668 9.99902 58.4335 9.99902 52.9669V16.6001C9.99902 11.1334 14.4657 6.7002 19.999 6.7002H59.999C65.5324 6.7002 69.999 11.1334 69.999 16.6001V52.9669C69.999 58.4002 65.5324 62.8668 59.999 62.8668Z"
      stroke="#FFB26B"
      strokeWidth="3"
      strokeliterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3323 30.5334C23.3323 27.4334 25.8657 24.8999 28.9657 24.8999C32.0657 24.8999 34.599 27.4334 34.599 30.5334C34.599 36.8001 25.699 37.4668 23.7323 43.4334C23.3323 44.6668 24.3657 45.9001 25.6657 45.9001H34.599"
      stroke="#04378E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.4656 45.8667V26.8335C53.4656 25.9668 52.899 25.2 52.0656 24.9667C51.2323 24.7333 50.3323 25.0667 49.8657 25.8C47.4657 29.6667 44.8656 34.0667 42.599 37.9334C42.2323 38.5667 42.2323 39.4 42.599 40.0334C42.9656 40.6667 43.6657 41.0665 44.4323 41.0665H56.6656"
      stroke="#04378E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
