import { Link } from "react-router-dom";

import { ArticlesGrid } from "../../modules/ArticlesGrid";
import { InviteGrid } from "../../modules/InviteGrid";
import { Container } from "../../components/Container";
import { MyButton } from "../../ui/MyButton";
import { JoinUs, LikeShapes, Lovely, Support } from "../../components/Icons";
import {
  Placeholder,
  ArtcileImage1,
  ArtcileImage2,
  ArtcileImage3,
  ArtcileImage4,
  ArtcileImage5,
  ArtcileImage6,
  ArtcileImage7,
  ArtcileImage8,
  ArtcileImage9,
  ArtcileImage10,
} from "../../assets/images";

import styles from "./news.module.css";

export const News = () => {
  const listArticles1 = [
    {
      date: "2.08.2023",
      descr:
        "Группа инженеров из Корейского института машин и материалов (KIMM) разработала инвалидную коляску, дающую пользователю возможность сидеть, стоять, наклоняться, полулежать и преодолевать любые лестницы на своём...",
      img: ArtcileImage1,
      title: "Новое инвалидное кресло позволяет «стоять» и ездить по лестницам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage2,
      title: "Вопреки инвалидности: перестаньте сравнивать себя с другими",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage3,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage4,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему Вы можете дать команду этим новым ",
      img: ArtcileImage5,
      title: "Заголовок рекламного блока до 3 строк",
      type: "Реклама",
      alt: "Placeholder",
    },
  ];
  const listArticles2 = [
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage6,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage7,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage8,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему автомобилю, просто используя приложение на вашем смартфоне. Возможно, самая раздражающая часть владения электромобилем – это его.",
      img: ArtcileImage9,
      title:
        "Ford тестирует роботизированные зарядные устройства для электромобилей, чтобы помочь водителям-инвалидам",
      likes: "24",
      views: "24",
      type: "Люди",
      alt: "Placeholder",
    },
    {
      date: "2.08.2023",
      descr:
        "Вы можете дать команду этим новым зарядным устройствам подключаться к вашему Вы можете дать команду этим новым ",
      img: ArtcileImage10,
      title: "Заголовок рекламного блока до 3 строк",
      type: "Реклама",
      alt: "Placeholder",
    },
  ];
  const listCardInvite = [
    {
      svg: LikeShapes,
      descr: "Сможете обмениваться опытом с другими и получать поддержку",
    },
    {
      svg: Support,
      descr: "Будете в курсе последних новостей в мире и стране",
    },
    {
      svg: JoinUs,
      descr: "Сможете сохранять любимые статьи в личном кабинете",
    },
    {
      svg: Lovely,
      descr: "Расширите круг общения и найдете новых друзей",
    },
  ];

  return (
    <section className={styles.news}>
      <Container>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Новости</h1>
          <Link to="/" className={styles.link}>
            смотреть все новости
          </Link>
        </div>
        <ArticlesGrid
          list={listArticles1}
          type={1}
          className={styles.articles1}
        />
        <img src={Placeholder} alt="Banner" className={styles.banner} />
        <ArticlesGrid
          list={listArticles2}
          type={2}
          className={styles.articles2}
        />
        <div className={styles.invite}>
          <h2 className={styles.inviteTitle}>
            Почему к нам стоит присоединиться?
          </h2>
          <InviteGrid className={styles.inviteGrid} list={listCardInvite} />
          <MyButton
            context="Присоединиться к нам"
            className={styles.inviteBtn}
          />
        </div>
      </Container>
    </section>
  );
};
