import React from "react";
import { Route, Routes } from "react-router-dom";

import { News } from "./pages/News";
import { Layout } from "./components/Layout";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<News />} />
      </Route>
      <Route path="*" element={<div>NoMatchPage</div>} />
    </Routes>
  );
};

export default App;
