import { Link } from "react-router-dom";

import { MyButton } from "../../ui/MyButton";
import { ArrowDown } from "../Icons";

import styles from "./dropdown.module.css";

export const DropDown = ({ dateDropdown }) => {
  return (
    <div className={styles.dropdown}>
      <MyButton
        context={dateDropdown.title}
        svg={ArrowDown}
        className={`${styles.title}`}
      />
      {dateDropdown.list.length > 0 && (
        <ul className={styles.list}>
          {dateDropdown.list.map((item, index) => (
            <li key={index} className={styles.item}>
              <Link to={item.link}>{item.context}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
