export const View = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M6.26707 16.8151C6.12873 16.5686 6.05956 16.4454 6.02084 16.2553C5.99175 16.1126 5.99175 15.8874 6.02084 15.7447C6.05956 15.5546 6.12873 15.4314 6.26707 15.1849C7.4103 13.1484 10.8132 8 15.999 8C21.1848 8 24.5877 13.1484 25.731 15.1849C25.8693 15.4314 25.9385 15.5546 25.9772 15.7447C26.0063 15.8874 26.0063 16.1126 25.9772 16.2553C25.9385 16.4454 25.8693 16.5686 25.731 16.8151C24.5877 18.8516 21.1848 24 15.999 24C10.8132 24 7.4103 18.8516 6.26707 16.8151Z"
      stroke="#636363"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.999 19.4286C17.6821 19.4286 19.0465 17.8935 19.0465 16C19.0465 14.1065 17.6821 12.5714 15.999 12.5714C14.3159 12.5714 12.9515 14.1065 12.9515 16C12.9515 17.8935 14.3159 19.4286 15.999 19.4286Z"
      stroke="#636363"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
