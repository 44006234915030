import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Searcher } from "../../components/Searcher";
import { Container } from "../../components/Container";
import { Clouds, Logo, Rain, Snow, Sun } from "../../components/Icons";
import { MyButton } from "../../ui/MyButton";
import { DropDown } from "../../components/DropDown/DropDown";

import styles from "./header.module.css";

export const Header = () => {
  const [weatherData, setWeatherData] = useState(null);
  const listDropdowns = [
    {
      title: "Новости",
      list: [
        {
          link: "/",
          context: "Люди",
        },
        {
          link: "/",
          context: "Событие",
        },
        {
          link: "/",
          context: "Реабилитация",
        },
        {
          link: "/",
          context: "Экспертиза",
        },
        {
          link: "/",
          context: "Личный опыт",
        },
      ],
    },
    {
      title: "Справочник",
      list: [
        {
          link: "/",
          context: "Инвалидные коляски",
        },
        {
          link: "/",
          context: "Пандусы, лифты, подъемники",
        },
        {
          link: "/",
          context: "Бытовые устройства",
        },
        {
          link: "/",
          context: "Ручное управление для авто",
        },
      ],
    },
    {
      title: "Решения",
      list: [],
    },
    {
      title: "Медиа",
      list: [],
    },
  ];
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "новября",
    "декабря",
  ];
  const minutes = new Date().getMinutes();
  const date = `${new Date().getHours()}:${
    `${minutes}`.length < 2 ? `0${minutes}` : minutes
  }`;
  const month = new Date().getDate() + " " + monthNames[new Date().getMonth()];

  useEffect(() => {
    function getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          showPosition,
          handleLocationError
        );
      }
    }

    function showPosition(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      const API_KEY = "59d68447b9ca815449e7228fc1fdb58e";
      const weatherAPIURL = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`;

      fetch(weatherAPIURL)
        .then((response) => response.json())
        .then((data) => {
          setWeatherData(data);
        })
        .catch((error) => {
          console.log("Ошибка при получении данных о погоде", error);
        });
    }

    function handleLocationError(error) {
      console.error("Ошибка при получении геолокации:", error);
    }

    getUserLocation();
  }, []);

  const selectWeatherIcon = (value) => {
    switch (value) {
      case "Snow":
        return Snow;
      case "Rain":
        return Rain;
      case "Clouds":
        return Clouds;
      case "Sunny":
        return Sun;
      default:
        return Clouds;
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.top}>
        <Container>
          <Link to={"/"} className={styles.logo}>
            {Logo} <span>езбарьерные решения</span>
          </Link>
          <div className={styles.actions}>
            <Searcher />
            <MyButton
              className={`${styles.login}`}
              context="Войти или зарегистрироваться"
            />
          </div>
        </Container>
      </div>
      <div className={styles.bottom}>
        <Container>
          <nav className={styles.nav}>
            {listDropdowns.map((dropdown, index) => (
              <DropDown dateDropdown={dropdown} key={index} />
            ))}
          </nav>
          <div className={styles.info}>
            <div className={styles.day}>
              <span className={styles.date}>{date}</span>
              <span className={styles.month}>{month}</span>
            </div>
            <div className={weatherData ? styles.weather : styles.weatherEmpty}>
              {weatherData ? (
                <span>
                  {Math.round(weatherData?.main?.temp)}{" "}
                  {selectWeatherIcon(weatherData?.weather[0]?.main)}
                </span>
              ) : (
                <span>Пока нет данных</span>
              )}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};
