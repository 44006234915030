export const Like = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
  >
    <path
      d="M11.619 18.8101C11.279 18.9301 10.719 18.9301 10.379 18.8101C7.47902 17.8201 0.999023 13.6901 0.999023 6.6901C0.999023 3.6001 3.48902 1.1001 6.55902 1.1001C8.37902 1.1001 9.98902 1.9801 10.999 3.3401C12.009 1.9801 13.629 1.1001 15.439 1.1001C18.509 1.1001 20.999 3.6001 20.999 6.6901C20.999 13.6901 14.519 17.8201 11.619 18.8101Z"
      stroke="#636363"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
