export const Clouds = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="26"
    viewBox="0 0 46 26"
    fill="none"
  >
    <path
      d="M8.5 14C4.35786 14 1 10.7488 1 6.73836C1 3.41439 3.5 0.34375 7.25 -0.25C8.4263 -2.98279 11.2103 -5 14.4573 -5C18.6167 -5 21.7663 -3.97303 22 0"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8 25C13.8353 25 9 20.3799 9 14.6808C9 9.95729 12.6 5.59375 18 4.75C19.6939 0.866567 23.7029 -2 28.3786 -2C34.3681 -2 39.2634 2.47912 39.6 8.125C42.7786 9.46512 45 12.7542 45 16.3154C45 21.1118 40.9705 25 36 25H19.8Z"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
