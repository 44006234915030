export const Lovely = (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.1989 26.9668C66.1989 28.3668 66.099 29.7335 65.9323 31.0335C64.399 30.3668 62.7323 30.0001 60.9657 30.0001C56.899 30.0001 53.299 31.9668 51.0657 34.9668C48.799 31.9668 45.199 30.0001 41.1323 30.0001C34.299 30.0001 28.7657 35.5668 28.7657 42.4668C28.7657 51.4001 33.499 58.2334 38.7657 62.8668C38.599 62.9668 38.4324 63.0002 38.2657 63.0669C37.2657 63.4335 35.599 63.4335 34.599 63.0669C25.9657 60.1002 6.66565 47.8335 6.66565 26.9668C6.66565 17.7668 14.0656 10.3335 23.199 10.3335C28.6323 10.3335 33.4323 12.9335 36.4323 16.9668C39.4656 12.9335 44.2656 10.3335 49.6656 10.3335C58.799 10.3335 66.1989 17.7668 66.1989 26.9668Z"
      stroke="#FFB26B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.3324 42.4666C73.3324 58.0666 58.8991 67.2667 52.4324 69.4667C51.6658 69.7333 50.4324 69.7333 49.6657 69.4667C46.899 68.5333 42.6657 66.3 38.7657 62.8666C33.4991 58.2333 28.7657 51.4 28.7657 42.4666C28.7657 35.5666 34.2991 30 41.1324 30C45.1991 30 48.7991 31.9666 51.0658 34.9666C53.2991 31.9666 56.8991 30 60.9658 30C62.7324 30 64.3991 30.3667 65.9324 31.0333C70.2991 32.9667 73.3324 37.3333 73.3324 42.4666Z"
      stroke="#04378E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
