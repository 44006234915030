import styles from './invite.module.css';

export const InviteCard = ({ svg, descr }) => {
  return (
    <div className={styles.card}>
      <p className={styles.descr}>{descr}</p>
      {svg}
    </div>
  );
};
