export const Snow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="33"
    viewBox="0 0 44 33"
    fill="none"
  >
    <path
      d="M8.5 4V19M11.8333 4.83333L8.5 8.16667L5.17398 4.83333M5.17398 18.1667L8.5 14.8333L11.8333 18.1667M1 11.5H16M1.83333 8.16667L5.17398 11.5L1.83333 14.8333M15.1667 14.8333L11.8333 11.5L15.1667 8.16667"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5 -7V6M29.3889 -6.27778L26.5 -3.38889L23.6175 -6.27778M23.6175 5.27778L26.5 2.38889L29.3889 5.27778M20 -0.5H33M20.7222 -3.38889L23.6175 -0.5L20.7222 2.38889M32.2778 2.38889L29.3889 -0.5L32.2778 -3.38889"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 12V32M37.4444 13.1111L33 17.5556L28.5653 13.1111M28.5653 30.8889L33 26.4444L37.4444 30.8889M23 22H43M24.1111 17.5556L28.5653 22L24.1111 26.4444M41.8889 26.4444L37.4444 22L41.8889 17.5556"
      stroke="#04378E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
